import * as sentry from '@sentry/react';
import ky from 'ky';
import { createContext, useContext, useEffect, useState } from 'react';

export const _api = ky.extend({
  prefixUrl: '/api/',
  credentials: 'include',
  hooks: {
    beforeError: [
      async (error) => {
        sentry.captureException(error);
        return error;
      }
    ]
  }
});

export const ApiContext = createContext({
  api: _api,
  setCsrfToken: (_token: string) => {
    return;
  }
});

export const ApiProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  useEffect(() => {
    _api.get('csrf').then(async (res) => {
      const { csrfToken }: { csrfToken: string } = await res.json();
      setCsrfToken(csrfToken);
    });
  }, []);
  const api = _api.extend({
    hooks: {
      beforeRequest: [
        async (request) => {
          if (csrfToken != null) {
            request.headers.set('CSRF-TOKEN', csrfToken);
          }
          return request;
        }
      ]
    }
  });
  return <ApiContext.Provider value={{ api, setCsrfToken }}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
