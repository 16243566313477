import { _api as api } from '@/components/context/ApiContext';
import type { SessionContextType } from '@/components/context/SessionContext';
import type { UseSuspenseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

export const getSession = {
  queryKey: ['session'],
  queryFn: async () => {
    const res: SessionContextType = await api
      .get('auth/me', { cache: 'no-cache', credentials: 'include' })
      .json();
    return res;
  },
  retry(failureCount, error) {
    if (error instanceof HTTPError && error.response.status === 401) {
      return false;
    }
    return failureCount < 2;
  },
  staleTime: 1000 * 60 * 5
} as UseSuspenseQueryOptions<SessionContextType>;
