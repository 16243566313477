import { getPartyQueryOptions } from '@/lib/queries/getParty';
import { createFileRoute } from '@tanstack/react-router';
import _short from 'short-uuid';

const short = _short();
export const Route = createFileRoute('/_app/party/$partyId/')({
  component: RouteComponent,
  loader: async ({ context: { queryClient }, params }) => {
    const partySlug = params.partyId;
    const partyId = short.validate(partySlug, true) ? short.toUUID(partySlug) : partySlug;
    console.debug('partyId: ', partyId);
    console.debug('partySlug: ', partySlug);
    return await queryClient.ensureQueryData(getPartyQueryOptions(partyId));
  }
});

function RouteComponent() {
  return 'Hello /_app/party/$partyId!';
}
