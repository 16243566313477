import { useTheme } from '@/components/ThemeSwitcher';
import { useSession } from '@/components/context/SessionContext';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';
import { Link, useLocation } from '@tanstack/react-router';
import { CircleUser, Film } from 'lucide-react';
import { useContext } from 'react';
import { DebugContext } from './context/DebugContext';

export default function NavBar() {
  const { user, logout } = useSession();
  const isLoggedIn = !!user; //!!session;
  // const isLoggedIn = true;

  const { debug } = useContext(DebugContext);
  if (debug) {
    console.debug('dev mode');
  }
  // const { session, user } = useContext(SessionContext);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    window.location.href = '/login';
    // await navigate({ to: '/login' });
  };

  const location = useLocation();
  const path = location.pathname;

  // <LoggedInContext.Consumer>
  // {({ isLoggedIn }) => {
  // console.log('navbar logged in: ', isLoggedIn);
  const { theme, toggleTheme } = useTheme();
  return (
    <header
      key={isLoggedIn ? 1 : 0}
      className='box-border flex w-full flex-row justify-between border-b px-4 py-2 dark:border-zinc-900'
    >
      {/*<div className=''>*/}
      {/*  <ThemeSwitcher />*/}
      {/*</div>*/}
      <div className={'my-auto font-semibold text-xl'}>
        <Link to={'/'} className={'flex flex-row items-center'}>
          <Film className='mr-2 h-6 w-6' />
          <span className={'tracking-tight dark:text-zinc-300 '}>
            Fli<span className={'px-[0.05rem] tracking-[-.1em]'}>xx</span>r
          </span>
        </Link>
        {/*<span className={'text-red-800'}>Flix</span>*/}
      </div>
      {/*{debug && (*/}
      {/*  <div className='navbar-center w-96 dark:text-white'>*/}
      {/*    session: {JSON.stringify(session)} <br /> user:{JSON.stringify(user)}*/}
      {/*  </div>*/}
      {/*)}*/}
      <nav className='navbar-end float-right flex flex-row items-center gap-4 sm:gap-6 '>
        {/*<nav className='ml-auto flex gap-4 sm:gap-6'>*/}
        {path === '/' && (
          <>
            <a
              className='font-medium text-sm underline-offset-4 hover:underline'
              href={'#features'}
            >
              Features
            </a>
            <a
              className='font-medium text-sm underline-offset-4 hover:underline'
              href={'#how-it-works'}
            >
              How It Works
            </a>
          </>
        )}
        {!isLoggedIn && (
          // <Button variant={'ghost'} className='' asChild>
          <Link className='font-medium text-sm underline-offset-4 hover:underline' to={'/signup'}>
            Get Started
          </Link>
          // <Link to={'/signup'}>Signup</Link>
          // </Button>
        )}

        {isLoggedIn && (
          // <Button variant={'ghost'} className='' asChild>
          <Link className='font-medium text-sm underline-offset-4 hover:underline' to={'/home'}>
            Home
          </Link>
          // <Link to={'/signup'}>Signup</Link>
          // </Button>
        )}

        <DropdownMenu>
          {/* // onClick={handleMenu}
            className='p-2'

            // component={RouterLink}
            // href='/auth/profile'
            // variant='contained'
          > */}
          <DropdownMenuTrigger className={'mr-2'}>
            {isLoggedIn ? (
              <>
                <Avatar>
                  <AvatarImage src='https://github.com/shadcn.png' alt='@shadcn' />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
              </>
            ) : (
              <CircleUser size={30} strokeWidth={1.25} />
            )}
          </DropdownMenuTrigger>

          <DropdownMenuContent className={'mt-4 mr-6'}>
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {isLoggedIn && (
              <>
                <DropdownMenuItem asChild>
                  <Link activeOptions={{ exact: true }} to='/account'>
                    Account
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuItem asChild>
                  <Link to='/account/settings'>Settings</Link>
                </DropdownMenuItem>

                <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
              </>
            )}

            {!isLoggedIn && (
              <>
                <DropdownMenuItem asChild>
                  <Link to='/signup'>Create account</Link>
                </DropdownMenuItem>
                <DropdownMenuItem className={'cursor-pointer'} asChild>
                  <Link to='/login'>Login</Link>
                </DropdownMenuItem>
              </>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleTheme();
              }}
              className={'flex flex-row justify-between'}
            >
              <LightMode
                className={`${theme === 'dark' ? 'text-orange-200/20' : 'text-orange-600 '} transition-all ease-in-out`}
              />
              <Switch
                checked={theme === 'dark'}
                // value={theme}
              />
              <DarkMode
                className={`${theme !== 'dark' ? 'text-blue-300/40' : 'text-blue-500 '} transition-all ease-in-out`}
              />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </nav>
    </header>
    //     );
    //   }}
    // </LoggedInContext.Consumer>
  );
}
