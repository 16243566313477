import { _api as api } from '@/components/context/ApiContext';
import type { paths } from '@repo/apitypes';
import type { UseQueryOptions } from '@tanstack/react-query';

export const getPartyList = ({ page, limit }: { page?: number; limit?: number }) => {
  const searchParams = new URLSearchParams();
  if (page) {
    searchParams.set('page', page?.toString() ?? '1');
  }
  if (limit) {
    searchParams.set('limit', limit?.toString() ?? '12');
  }
  return {
    queryKey: ['party', 'list', { page, limit }],
    queryFn: async () => {
      return (await api.get('parties', { searchParams }).json()) as PartyList;
    }
  } as UseQueryOptions<PartyList>;
};

export type PartyList = paths['/parties']['get']['responses']['200']['content']['application/json'];
