'use client';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Link, createFileRoute } from '@tanstack/react-router';
import { Play, ThumbsUp, Users } from 'lucide-react';

export const Route = createFileRoute('/')({ component: Home });
export default function Home() {
  return (
    <div className='flex min-h-screen flex-col'>
      {/*<header className='flex h-14 items-center px-4 lg:px-6'>*/}
      {/*  /!*<Link className='flex items-center justify-center' to='/'>*!/*/}
      {/*  /!*  <Film className='mr-2 h-6 w-6' />*!/*/}
      {/*  /!*  <span className='font-bold'>MovieMatcher</span>*!/*/}
      {/*  /!*</Link>*!/*/}
      {/*  <nav className='ml-auto flex gap-4 sm:gap-6'>*/}
      {/*    /!*<Link className='font-medium text-sm underline-offset-4 hover:underline' to={'/'}>*!/*/}
      {/*    /!*  Features*!/*/}
      {/*    /!*</Link>*!/*/}
      {/*    /!*<Link className='font-medium text-sm underline-offset-4 hover:underline' to='/'>*!/*/}
      {/*    /!*  How It Works*!/*/}
      {/*    /!*</Link>*!/*/}
      {/*    /!*<Link className='font-medium text-sm underline-offset-4 hover:underline' to={'/'}>*!/*/}
      {/*    /!*  Get Started*!/*/}
      {/*    /!*</Link>*!/*/}
      {/*  </nav>*/}
      {/*</header>*/}
      <main className='flex-1'>
        <section className='w-full py-12 md:py-24 lg:py-32 xl:py-48'>
          <div className='w-full px-4 md:px-6'>
            <div className='flex flex-col items-center space-y-4 text-center'>
              <div className='space-y-2'>
                <h1 className='font-bold text-3xl tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none'>
                  Find Your Perfect Movie Night
                </h1>
                <p className='mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400'>
                  MovieMatcher helps you and your friends decide on the perfect movie to watch
                  together. No more endless scrolling or debates.
                </p>
              </div>
              <div className='space-x-4'>
                <Link to={'/signup'}>
                  <Button>Get Started</Button>
                </Link>
                <Button variant='outline'>Learn More</Button>
              </div>
            </div>
          </div>
        </section>
        <section
          id='features'
          className='w-full bg-gray-100 py-12 md:py-24 lg:py-32 dark:bg-stone-800'
        >
          <div className='w-full px-4 md:px-6'>
            <h2 className='mb-8 text-center font-bold text-3xl tracking-tighter sm:text-4xl md:text-5xl'>
              Features
            </h2>
            <div className='grid gap-10 sm:grid-cols-2 md:grid-cols-3'>
              <div className='flex flex-col items-center space-y-2 rounded-lg border-gray-800 p-4'>
                <Users className='mb-2 h-10 w-10' />
                <h3 className='font-bold text-xl'>Group Matching</h3>
                <p className='text-center text-gray-500 text-sm dark:text-gray-400'>
                  Create a group and invite your friends to join. We'll find movies that everyone
                  will enjoy.
                </p>
              </div>
              <div className='flex flex-col items-center space-y-2 rounded-lg border-gray-800 p-4'>
                <ThumbsUp className='mb-2 h-10 w-10' />
                <h3 className='font-bold text-xl'>Preference Matching</h3>
                <p className='text-center text-gray-500 text-sm dark:text-gray-400'>
                  Our algorithm considers everyone's preferences to suggest the best movies for your
                  group.
                </p>
              </div>
              <div className='flex flex-col items-center space-y-2 rounded-lg border-gray-800 p-4'>
                <Play className='mb-2 h-10 w-10' />
                <h3 className='font-bold text-xl'>Quick Decision</h3>
                <p className='text-center text-gray-500 text-sm dark:text-gray-400'>
                  Get movie suggestions in seconds. Spend less time deciding and more time watching.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id='how-it-works' className='w-full py-12 md:py-24 lg:py-32'>
          <div className='w-full px-4 md:px-6'>
            <h2 className='mb-8 text-center font-bold text-3xl tracking-tighter sm:text-4xl md:text-5xl'>
              How It Works
            </h2>
            <ol className='grid gap-8 sm:grid-cols-2 md:grid-cols-3'>
              <li className='flex flex-col items-center space-y-2'>
                <span className='flex h-12 w-12 items-center justify-center rounded-full bg-primary font-bold text-white text-xl'>
                  1
                </span>
                <h3 className='font-bold text-xl'>Create a Group</h3>
                <p className='text-center text-gray-500 text-sm dark:text-gray-400'>
                  Start a new movie night and invite your friends to join your group.
                </p>
              </li>
              <li className='flex flex-col items-center space-y-2'>
                <span className='flex h-12 w-12 items-center justify-center rounded-full bg-primary font-bold text-white text-xl'>
                  2
                </span>
                <h3 className='font-bold text-xl'>Set Preferences</h3>
                <p className='text-center text-gray-500 text-sm dark:text-gray-400'>
                  Each member sets their movie preferences and mood for the night.
                </p>
              </li>
              <li className='flex flex-col items-center space-y-2'>
                <span className='flex h-12 w-12 items-center justify-center rounded-full bg-primary font-bold text-white text-xl'>
                  3
                </span>
                <h3 className='font-bold text-xl'>Get Matches</h3>
                <p className='text-center text-gray-500 text-sm dark:text-gray-400'>
                  Our algorithm suggests the best movies that everyone in the group will enjoy.
                </p>
              </li>
            </ol>
          </div>
        </section>
        <section
          id='get-started'
          className='w-full bg-gray-100 py-12 md:py-24 lg:py-32 dark:bg-stone-800'
        >
          <div className='w-full px-4 md:px-6'>
            <div className='flex flex-col items-center space-y-4 text-center'>
              <div className='space-y-2'>
                <h2 className='font-bold text-3xl tracking-tighter sm:text-4xl md:text-5xl'>
                  Ready for Your Next Movie Night?
                </h2>
                <p className='mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400'>
                  Sign up now and start matching movies with your friends. Your perfect movie night
                  is just a click away!
                </p>
              </div>
              <div className='w-full max-w-sm space-y-2'>
                <div className='flex space-x-2'>
                  <Input
                    className='max-w-lg flex-1 bg-white shadow dark:border-none dark:bg-neutral-950'
                    placeholder='Enter your email'
                    type='email'
                  />
                  <Link to={'/signup'}>
                    <Button type='submit'>Sign Up</Button>
                  </Link>
                </div>
                <p className='text-gray-500 text-xs dark:text-gray-400'>
                  By signing up, you agree to our{' '}
                  <Link className='underline underline-offset-2' to={'/'}>
                    Terms & Conditions
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className='flex w-full shrink-0 flex-col items-center gap-2 border-t/ px-4 py-6 sm:flex-row md:px-6'>
        <p className='text-gray-500 text-xs dark:text-gray-400'>
          © 2023 MovieMatcher. All rights reserved.
        </p>
        <nav className='flex gap-4 sm:ml-auto sm:gap-6'>
          <Link className='text-xs underline-offset-4 hover:underline' to={'/'}>
            Terms of Service
          </Link>
          <Link className='text-xs underline-offset-4 hover:underline' to={'/'}>
            Privacy
          </Link>
        </nav>
      </footer>
    </div>
  );
}
