import { useApi } from '@/components/context/ApiContext';
import { getServerInfo } from '@/lib/queries/getServerInfo';
import { getSession } from '@/lib/queries/getSession';
import type { paths } from '@repo/apitypes';
import * as Sentry from '@sentry/react';
import { keepPreviousData, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { type ReactNode, createContext, useContext } from 'react';
import toast from 'react-hot-toast';

type TUser = paths['/auth/me']['get']['responses']['200']['content']['application/json']['user'];

type TSession =
  paths['/auth/me']['get']['responses']['200']['content']['application/json']['session'];

export type SessionContextType = {
  session: TSession | null;
  user: TUser | null;
  logout: () => Promise<void>;
};
export const SessionContext = createContext<SessionContextType>({
  session: null,
  user: null,
  logout: async () => {
    console.warn('Logout called without initialised session manager');
  }
});

export function SessionProvider({ children }: { children: ReactNode | ReactNode[] }) {
  // return <>{children}</>;
  const { api } = useApi();
  const queryClient = useQueryClient();
  const { data: info, isError: serverIsError } = useSuspenseQuery({ ...getServerInfo });
  console.info('Server info:', info);
  if (serverIsError) {
    throw new Error('Server api error');
  }

  const { data, isPending } = useSuspenseQuery({ ...getSession });

  Sentry.setUser({ ...data?.user, email: data?.user?.email ?? undefined });

  const handleLogout = async () => {
    try {
      await api.post('auth/logout', { credentials: 'include' });
      await queryClient.invalidateQueries({ queryKey: ['session'] });
    } catch (e) {
      console.error(e);
      toast.error('Error logging out');
    }
  };

  if (isPending) {
    return <div>Loading session...</div>;
  }
  return (
    <SessionContext.Provider
      value={{ session: data?.session, user: data?.user, logout: handleLogout }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return useContext(SessionContext);
}
